// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "t_ck";
export var reactAppDevCardBorderIconSection = "t_cj";
export var reactAppDevCaseSection = "t_cq";
export var reactAppDevClientsSection = "t_cs";
export var reactAppDevExpertiseSection = "t_cr";
export var reactAppDevIndustriesSection = "t_cl";
export var reactAppDevPreferencesSection = "t_cp";
export var reactAppDevProcessSection = "t_cm";
export var reactAppDevServicesIconSection = "t_ch";
export var reactAppDevTechStackSection = "t_cn";